<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9" style="direction: ltr">
      <v-card class="d-flex justify-center">
        <div style="width: 44mm; margin-top: 25px; color: black !important">
          <h1 style="font-size: 1.2em; text-align: center; color: black !important" v-if="results.center_id != '646797355f6c4b0e341a8e7f'">{{ results.center_name }}</h1>
          <h2 style="font-size: 0.9em; color: black !important">contact info</h2>
          <p style="font-size: 0.8em; font-weight: bold">
            client name: {{ billAccountData.customer_name }}
            <br />
            Date # {{ getDateInAmAndPm() }}
          </p>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Price</th>
                <th>Desc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ billAccountData.service_name }}</td>
                <td>{{ numberWithComma(billAccountData.total_amount) }}</td>
                <td>{{ billAccountData.desc }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <!-- <v-btn color="primary" class="mb-3" block>
            <v-icon class="me-2" left>
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn> -->
          <!-- <v-btn class="mb-3" color="secondary" block outlined>
            Download
          </v-btn> -->
          <v-btn class="mb-3" color="secondary" block outlined @click="printInvoice"> طباعة </v-btn>
          <!-- <v-btn class="mb-3" color="secondary" block outlined>
            Edit Invoice
          </v-btn>
          <v-btn color="success" block>
            <v-icon class="me-2" left>
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            <span>Add Payment</span>
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-col>

    <!-- invoice send drawer -->
    <!-- <invoice-sidebar-send-invoice v-model="isInvoiceSendSidebarActive"></invoice-sidebar-send-invoice> -->

    <!-- invoice add payment drawer -->
    <!-- <invoice-sidebar-add-payment v-model="isAddPaymentSidebarActive"></invoice-sidebar-add-payment> -->
  </v-row>
</template>

<script>
import numberWithComma from '@/constant/number'
import { mdiCurrencyUsd, mdiSendOutline } from '@mdi/js'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  //   components: {
  //     InvoiceSidebarSendInvoice,
  //     InvoiceSidebarAddPayment,
  //   },

  data() {
    return {
      invoiceData: null,

      paymentDetails: {},

      billInfo: {
        _id: null,
        student_id: null,
        desc: null,
        service_type: null,
        school_id: null,
        is_deleted: null,
        createdAt: null,
        updatedAt: null,
        salaryAmount: null,
        discountAmount: null,
        paymentAmount: null,
        currency: null,
        currencySymbol: ' IQD',
        nextPaymentDate: null,
        remainingAll: 0,
        student_name: null,
        service_name: null,
      },

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },

      results: {},

      billAccountData: {},

      dateString: null,

      responseDB: [],
    }
  },

  async created() {
    this.results = JSON.parse(localStorage.getItem('results'))

    this.billAccountData = JSON.parse(localStorage.getItem('anotherBillDetailsInvoice'))

    // const response = await salary_other_payments_api.get(this.billAccountData._id)

    // this.responseDB = response.data.results

    const date = new Date()

    this.dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
  },

  methods: {
    printInvoice() {
      window.print()
    },

    async getOnlyPaymentAndDiscountOfPayment(payments) {
      let payementsOrginazed = []
      for await (const iterator of payments) {
        if (iterator.type != 'salary') {
          payementsOrginazed.push(iterator)
        }
      }

      return payementsOrginazed
    },

    getDateAndTime(milisecondsDate) {
      let date = new Date(milisecondsDate)

      return date.toLocaleString('en-Us')
    },

    getCurrentDateTime() {
      var currentDate = new Date()

      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()

      var hours = currentDate.getHours()
      var minutes = currentDate.getMinutes()
      var ampm = hours >= 12 ? 'PM' : 'AM'

      // Convert hours to 12-hour format
      hours = hours % 12
      hours = hours ? hours : 12 // "0" should be converted to "12"

      // Add leading zeros to day, month, hours, and minutes if necessary
      day = this.addLeadingZero(day)
      month = this.addLeadingZero(month)
      hours = this.addLeadingZero(hours)
      minutes = this.addLeadingZero(minutes)

      var formattedDateTime = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm
      return formattedDateTime
    },

    getDateInAmAndPm() {
      var currentDate = new Date(this.billAccountData.createdAt)

      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()

      var hours = currentDate.getHours()
      var minutes = currentDate.getMinutes()
      var ampm = hours >= 12 ? 'PM' : 'AM'

      // Convert hours to 12-hour format
      hours = hours % 12
      hours = hours ? hours : 12 // "0" should be converted to "12"

      // Add leading zeros to day, month, hours, and minutes if necessary
      day = this.addLeadingZero(day)
      month = this.addLeadingZero(month)
      hours = this.addLeadingZero(hours)
      minutes = this.addLeadingZero(minutes)

      var formattedDateTime = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ' ' + ampm
      return formattedDateTime
    },

    addLeadingZero(number) {
      return number < 10 ? '0' + number : number
    },

    numberWithComma,
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

table th {
  background-color: #f5f5f5;
  color: #000;
}
table td,
th {
  padding: 8px;
}
@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
